<template>
  <b-row>
    <b-col lg="4">
      <b-card>
        <b-row>
          <b-col v-if="sellers.staff">
            <b-avatar :src="sellers.staff.image"
                      v-if="sellers.staff.image">
            </b-avatar>
            <b-avatar v-else         
                      icon="building"                                    
                      variant="dark">
            </b-avatar>     
            &nbsp;<b>{{sellers.staff.name}}</b>     
          </b-col>
        </b-row>

        <b-row>
          <b-col>                    
            
            <div class="ml-5">
              <div v-if="!sellers.email">
                <b-icon icon="envelope"></b-icon> &nbsp;&nbsp;
                <b-badge variant="warning" class="badget-custom">No tiene un email asociado</b-badge>                                                    
              </div>
              <div v-else>
                <b-icon icon="envelope"></b-icon> &nbsp;&nbsp;
                <b-link :href="'mailto:' + sellers.email">
                  {{ sellers.email }}
                </b-link> 
              </div>
            </div>
            
            <div class="ml-5" v-if="sellers.phone">
              <b-icon icon="phone"></b-icon> &nbsp;&nbsp;
              <b-link :href="'tel:' + sellers.phone">
                {{ sellers.phone }}
              </b-link>               
            </div>                    

          </b-col>
        </b-row>
      </b-card>      
    </b-col> 
    <b-col lg="8">
      <b-row>
        <b-col lg="9">
          <b-card>
            <b-card-group class="mt-2" v-if="!isBusy">
              <b-card
                border-variant="dark"
                header-bg-variant="dark"
                header-text-variant="white"
                align="center"
                class="crud-seller-access-reports-widget-card">
                <div slot="header" class="font-weight-bold">
                  <strong> Historial de Informes </strong>                        
                </div> 
                <b-card-text class="crud-seller-access-reports-widget-scroll">
                  
                  <b-list-group v-if="arrReports.length > 0">
                    <b-list-group-item class="flex-column align-items-start crud-seller-access-reports-widget-link-reports" 
                                        v-for="value in arrReports" 
                                        v-bind:key="value.created_at" 
                                        @click="goDetailReport(value, value.type)">
                      <b-row>                  
                        <b-col>                          
                          <div v-if="value.type=='COBRANZAS'"
                                class="crud-seller-access-reports-widget-info-extra">
                            NRO RECIBO: <b>{{value.id}}</b>
                          </div>
                          <div class="d-flex w-100 justify-content-between">                    
                            <h5 class="mb-1">Informe de <u>{{value.type}}</u></h5>    
                            <small class="crud-seller-access-reports-widget-date">                                                
                              {{moment(value.date).format('DD/MM/YYYY')}}
                            </small>                            
                          </div> 
                          <div v-if="value.responsable" class="text-left mt-1 mb-2">
                            <b-avatar :src="value.responsable.image"
                                      v-if="value.responsable.image">
                            </b-avatar>
                            <b-avatar v-else    
                                      icon="building"              
                                      variant="dark">
                            </b-avatar>     
                            &nbsp;<b>{{value.responsable.name}}</b>
                            <b-icon icon="circle-fill" v-if="!value.responsable.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />
                          </div>                                                               
                          <div class="crud-seller-access-reports-widget-info-extra">  
                            <div v-if="value.observations">
                              {{value.observations}}
                            </div>      
                              
                            <b-badge v-if="!value.read" variant="dark" pill class="pull-right" >SIN ABRIR</b-badge>
                            <b-badge v-else variant="secondary" pill class="pull-right" >LEIDO</b-badge>
                          </div>                                      
                        </b-col>
                      </b-row>                  
                    </b-list-group-item>                    
                  </b-list-group>
                            
                  <b-alert v-else show variant="warning">
                    No se encontraron registros
                  </b-alert>  

                </b-card-text>
              </b-card>
            </b-card-group>
            <div class="text-center" v-else>
              <b-spinner label="Spinning"></b-spinner>
            </div>            
          </b-card>          
        </b-col>
        <b-col lg="3">          
          <b-list-group class="mb-1 view-customer-access-reports" v-if="reports.access.visits">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="goReportVisit()">
              <div class="d-flex w-100 justify-content-between">
                <h5>Visitas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>
            </b-list-group-item>
          </b-list-group>

          <b-list-group class="mb-1 view-customer-access-reports" v-if="reports.access.payments">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="goReportPayment()">
              <div class="d-flex w-100 justify-content-between">
                <h5>Cobranzas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>
            </b-list-group-item>
          </b-list-group>

          <b-list-group class="mb-1 view-customer-access-reports" v-if="reports.access.news">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="goReportNews()">
              <div class="d-flex w-100 justify-content-between">
                <h5>Novedades</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>
            </b-list-group-item>
          </b-list-group>

          <b-list-group class="mb-1 view-customer-access-reports" v-if="reports.access.general">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="goReportGeneral()">
              <div class="d-flex w-100 justify-content-between">
                <h5>General</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>
            </b-list-group-item>
          </b-list-group>          
        </b-col>
      </b-row>   
    </b-col>     
    <b-col lg="12">
      <b-card class="mt-2" header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
        </b-row>
      </b-card>      
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Profiles from '@/config/profiles'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment' 

  export default {   
    data: () => {
      return {      
        access: {
          module_id: Modules.VENDEDORES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudSellersReports',
          elements: {}
        }, 
        sellersID: 0,
        sellers: {},
        arrReports: [],
        reports: {
          access: {
            visits: false,
            payments: false,
            news: false,
            general: false,
          },
        },
        modulesActive: [],  
        isBusy: true, 
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted () {            
      this.sellersID = this.$route.params.sellersID
      this.modulesActive = Session.getSession().auth.user.permissions.modules

      this.setup()      
      this.load() 
      this.configAccess()     
    },  
    computed: {
      isSeller(){
        if(Helper.getSeller()) {
          return true
        } else {
          return false
        }
      }      
    },    
    methods: {
      getRowCount (items) {
        return items.length
      },
      load() {
        this.loadSeller()
        this.loadReports()
      },
      loadSeller() {           
        let loader = this.$loading.show();

        var result = serviceAPI.mostrarVendedor(this.sellersID)

        result.then((response) => {                    
          var data = response.data          
          this.sellers = data              
                                             
          loader.hide()
        })
        .catch(error => {          
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });          
      },    
      loadReports() {
        this.isBusy = true
        var result = serviceAPI.obtenerReportsBySellers({
          seller_id: this.sellersID
        })

        result.then((response) => {          
          var data = response.data
          this.arrReports = data   
          this.isBusy = false                
        })
        .catch(error => {          
          this.isBusy = false
          this.$awn.alert(Error.showError(error))
        });           
      },
      goDetailReport(item, type) {        
        if(type=='VISITAS') {        
          this.$router.push({ name: 'ReportsVisitsStaffDetailReport', params: {reportID: item.id} })
        }

        if(type=='COBRANZAS') {
          this.$router.push({ name: 'ReportsPaymentStaffDetailReport', params: {reportID: item.id} })
        }

        if(type=='NOVEDADES') {
          this.$router.push({ name: 'ReportsNewsStaffDetailReport', params: {reportID: item.id} })
        }

        if(type=='GENERAL') {
          this.$router.push({ name: 'ReportsGeneralStaffDetailReport', params: {reportID: item.id} })
        }        
      },    
      configAccess() {
        this.modulesActive.forEach(element => {

          if(element.id == Modules.INFORME_VISITAS ||
             element.id == Modules.INFORME_COBRANZAS ||
             element.id == Modules.INFORME_NOVEDADES ||
             element.id == Modules.INFORME_GENERAL) {
              
              if(element.id == Modules.INFORME_VISITAS) {
                this.reports.access.visits = true
              }
              if(element.id == Modules.INFORME_COBRANZAS) {
                this.reports.access.payments = true
              }
              if(element.id == Modules.INFORME_NOVEDADES) {
                this.reports.access.news = true
              } 
              if(element.id == Modules.INFORME_GENERAL) {
                this.reports.access.general = true
              } 
          }

        })
      },
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()          
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"
        hoja.innerHTML = hoja.innerHTML  + ".view-customer-access-reports-widget-card-text-primary { color: " + this.primaryColor + "; }"
        
        document.body.appendChild(hoja);
      },

      goReportVisit() {
        if(this.isSeller) {
          this.$router.push({ name: 'ReportsVisitsStaffCrudReportSeller' })
        } else {
          this.$router.push({ name: 'ReportsVisitsStaffCrudReport' })
        }        
      },
      goReportPayment() {        
        if(this.isSeller) { 
          this.$router.push({ name: 'ReportsPaymentStaffCrudReportSeller' })
        } else {
          this.$router.push({ name: 'ReportsPaymentStaffCrudReport' })
        }                  
      },
      goReportNews() {
        if(this.isSeller) { 
          this.$router.push({ name: 'ReportsNewsStaffCrudReportSeller' })
        } else {
          this.$router.push({ name: 'ReportsNewsStaffCrudReport' })
        }        
      }, 
      goReportGeneral() {
        if(this.isSeller) { 
          this.$router.push({ name: 'ReportsGeneralStaffCrudReportSeller' })
        } else {
          this.$router.push({ name: 'ReportsGeneralStaffCrudReport' })
        }         
      },       
    }
  }
</script>
<style>
  .crud-seller-access-reports .list-group-item {
    padding: 6px;
  }
  .crud-seller-access-reports h5 {
    margin-bottom: 0px;
  }
  .crud-seller-access-reports svg {
    margin-bottom: 0px;
  }  
  .crud-seller-access-reports-widget-card .card-header {
    padding: 5px;
  }
  .crud-seller-access-reports-widget-card .card-body {
    padding: 5px;
  }  
  .crud-seller-access-reports-widget-card-text-info {
    font-size: 12px;
  }
  .crud-seller-access-reports-widget-card-text-primary {
    font-size: 25px;  
    font-weight: bold;
  }  
  .crud-seller-access-reports-widget-card-empty {
    font-size: 15px;
    margin-top: 10px;
  }

  .crud-seller-access-reports-widget-link-reports {
    cursor: pointer;
  }
  .crud-seller-access-reports-widget-badget-custom {
    font-size: 100%;
  }  
  .crud-seller-access-reports-widget-date {
    font-size: 13px;
  }
  .crud-seller-access-reports-widget-info-extra {
    text-align: left;
    font-size: 15px;
  }
  .crud-seller-access-reports-widget-scroll {
    height: 550px;
    overflow: overlay;
  }
</style>